import React, { useRef, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import { addNewServiceAddressesApi, getAllServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { AddressAutofill } from '@mapbox/search-js-react';
import { isNull } from 'lodash';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import { phone_add_plus_one, phone_remove_plus_one } from '../../../utils/commonUtil';
import { phoneTypes } from '../../../mockdata/common';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
const MAPBOX_API_TOKEN =
  import.meta.env.VITE_TENANT_NAME === 'laundrycare'
    ? import.meta.env.VITE_LAUNDRYCARE_MAPBOX_API_TOKEN
    : import.meta.env.VITE_TENANT_NAME === 'pushlaundry'
    ? import.meta.env.VITE_PUSHLAUNDRY_MAPBOX_API_TOKEN
    : '';

export default function NewServiceAddressModal({ cancel = false, setModalOpen }) {
  const dispatch = useDispatch();
  const nameField = useRef(null);
  const address1Field = useRef(null);
  const address2Field = useRef(null);
  const cityField = useRef(null);
  const stateField = useRef(null);
  const zipField = useRef(null);
  const [phone, setPhone] = useState({
    type: 'MOBILE',
    phone_number: '',
  });
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [addressData, setAddressData] = useState(null);
  const [loading, setLoading] = useState(false);
  const addressChangeFn = (data) => {
    setAddressData((prev) => ({
      ...prev,
      address_line_1: data.features[0].properties.address_line1,
      address_line_2: data.features[0].properties.address_line2,
      city: data.features[0].properties.address_level2,
      state: data.features[0].properties.address_level1,
      postal_code: data.features[0].properties.postcode,
    }));
  };

  const validateAddressFn = (address) => {
    const fields = ['name', 'address_line_1', 'city', 'state', 'postal_code'];
    for (const field of fields) {
      if (!address[field] || address[field] === '') {
        toast.error(`Please enter a ${field.replace('_', ' ')}`);
        setAddressData((prev) => ({ ...prev, [field]: null }));
        return false;
      }
    }
    return true;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPhone((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmitAddress = (e) => {
    e.preventDefault();
    const payload = {
      name: nameField.current.value,
      address_line_1: address1Field.current.value,
      address_line_2: address2Field.current.value,
      city: cityField.current.value,
      state: stateField.current.value,
      postal_code: zipField.current.value,
    };
    if (validateAddressFn(payload)) {
      if (phone?.phone_number == '') {
        setPhoneNumberError(true);
      } else {
        setPhoneNumberError(false);
        setLoading(true);
        const create = dispatch(addNewServiceAddressesApi(payload));
        create.then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            updatePhoneNumberApi({
              phone_number: {
                phone_number: phone_add_plus_one(phone?.phone_number),
                type: phone.type,
              },
            });
          } else {
            setLoading(false);
            if (res?.message == `The address ${addressData?.name} does not fall in any active service area.`) {
              toast.error(
                'The address you entered is currently outside our active service areas. Please double-check your address or contact our support team for assistance.'
              );
            } else {
              toast.error(res?.message || 'Something went wrong');
            }
          }
        });
      }
    }
  };

  const updatePhoneNumberApi = (payload) => {
    const create = updateProfileApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setLoading(false);
        onCancel();
        dispatch(getAllServiceAddressesApi());
        toast.success(res?.error?.message || 'New address added successfully');
        dispatch(getProfileDataApi(false));
      } else {
      }
    });
  };
  const onCancel = () => {
    cancel && setModalOpen(false);
  };

  return (
    <>
      <div className=''>
        {loading ? (
          <Loader text='processing...' classes='h-40' />
        ) : (
          <form onSubmit={handleSubmitAddress}>
            <div className='grid gap-5  p-5 md:grid-cols-3'>
              <div>
                <div>
                  <label className='go-label-16 mb-2 block' htmlFor='name'>
                    Label
                  </label>
                  <input
                    ref={nameField}
                    name='name'
                    className={`go-inputtext-16 ${isNull(addressData?.name) ? 'border-red-500' : ''}`}
                    type='text'
                    defaultValue={addressData?.name}
                    onChange={(e) => {
                      setAddressData((prev) => ({ ...prev, name: e.target.value }));
                    }}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16 mb-2 block' htmlFor='address_line_1'>
                    Street Address
                  </label>
                  <AddressAutofill
                    accessToken={MAPBOX_API_TOKEN}
                    options={{
                      language: 'en',
                      country: 'US',
                    }}
                    onRetrieve={addressChangeFn}
                  >
                    <input
                      ref={address1Field}
                      name='address_line_1'
                      className={`go-inputtext-16 ${isNull(addressData?.address_line_1) ? 'border-red-500' : ''}`}
                      type='text'
                      placeholder='Address'
                      autoComplete='address-line1'
                      defaultValue={addressData?.address_line_1}
                    />
                  </AddressAutofill>
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16 mb-2 block' htmlFor='address_line_2'>
                    Apartment or suite number
                  </label>
                  <input
                    ref={address2Field}
                    name='address_line_2'
                    className={`go-inputtext-16`}
                    type='text'
                    placeholder='Apartment number'
                    autoComplete='address-line2'
                    defaultValue={addressData?.address_line_2}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className='go-label-16 mb-2 block' htmlFor='city'>
                    City
                  </label>
                  <input
                    ref={cityField}
                    name='city'
                    className={`go-inputtext-16 ${isNull(addressData?.city) ? 'border-red-500' : ''}`}
                    type='text'
                    placeholder='City'
                    autoComplete='address-level2'
                    defaultValue={addressData?.city}
                  />
                </div>
              </div>
              <div className='flex space-x-2'>
                <div className='flex-1'>
                  <div>
                    <label className='go-label-16 mb-2 block' htmlFor='state'>
                      State
                    </label>
                    <input
                      ref={stateField}
                      name='state'
                      className={`go-inputtext-16 ${isNull(addressData?.state) ? 'border-red-500' : ''}`}
                      type='text'
                      placeholder='State'
                      autoComplete='address-level1'
                      defaultValue={addressData?.state}
                    />
                  </div>
                </div>
                <div className='flex-1'>
                  <div>
                    <label className='go-label-16 mb-2 block' htmlFor='postal_code'>
                      Zip Code
                    </label>
                    <input
                      ref={zipField}
                      name='postal_code'
                      className={`go-inputtext-16 ${isNull(addressData?.postal_code) ? 'border-red-500' : ''}`}
                      type='number'
                      placeholder='Postcode'
                      autoComplete='postal-code'
                      defaultValue={addressData?.postal_code}
                      maxLength={5}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p className='go-textblacknormal-16  pt-5 pl-5'>Enter Your Phone Number</p>{' '}
            <div className='grid gap-5 p-5 md:grid-cols-3'>
              <div className='relative mb-4 flex-row space-y-1.5'>
                <select
                  className='go-label-16 block w-full border-transparent p-0 focus:border-transparent focus:ring-0 '
                  id='type'
                  name='type'
                  value={phone.type}
                  onChange={handleChange}
                >
                  {phoneTypes?.map((option) => {
                    return (
                      <option key={option?.id} value={option?.value}>
                        {option?.displayValue}
                      </option>
                    );
                  })}
                </select>
                <label className='go-label-16 mb-1 hidden' htmlFor='label'>
                  Phone Number
                </label>
                <input
                  type='tel'
                  className={`go-inputtext-16`}
                  id='phone_number'
                  name='phone_number'
                  pattern='\d{10}'
                  defaultValue={phone_remove_plus_one(phone.phone_number)}
                  onChange={handleChange}
                />
                {phoneNumberError && <p className='text-red-500'>Please enter a valid phone number</p>}
              </div>
            </div>
            <div className={`flex space-x-4 p-5 ${cancel ? 'sm:w-6/12' : 'sm:w-3/12'}`}>
              {cancel && (
                <Button
                  variant='secondary'
                  text='Cancel'
                  classes='w-full'
                  onClick={(e) => {
                    onCancel();
                  }}
                />
              )}
              <Button variant='primary-dark' text='Save Address' classes='w-full' type='submit' />
            </div>
          </form>
        )}
      </div>
    </>
  );
}
