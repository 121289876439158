import React, { useEffect, useState } from 'react';
import InvoiceDetails from './InvoiceDetails';
import AssociatedOrders from './AssociatedOrders';
import InvoiceFooter from './InvoiceFooter';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from 'react-router-dom';
import { getInvoiceDetailsbyID } from '../../redux/Reducers/PaymentsReducer';
import { toast } from 'react-hot-toast';
import PayInvoice from '../PayInvoice';
import { getBillingDetailsApi } from '../../redux/Reducers/commonApis';
import { useSelector } from 'react-redux';
export default function Invoice() {
  const { id } = useParams();
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [billingDetails, setBillingDetails] = useState({});
  const [openInovice, setOpenInovice] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const getInvoiceDetails = (id) => {
    setInvoiceLoading(true);
    const response = getInvoiceDetailsbyID(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setInvoice(res?.data);
        setInvoiceLoading(false);
      } else {
        setInvoiceLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const getBillingDetailsFn = (id) => {
    const response = getBillingDetailsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setBillingDetails(res?.data || {});
      } else {
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    id && getInvoiceDetails(id);
    if (selectedAddress?.id) getBillingDetailsFn(selectedAddress?.id);
  }, [id]);

  return (
    <div className='sm:max-w-9xl w-full p-4 sm:mx-auto sm:p-6'>
      <InvoiceDetails invoice={invoice} invoiceLoading={invoiceLoading} />
      <AssociatedOrders invoice={invoice} invoiceLoading={invoiceLoading} />
      <InvoiceFooter
        open={openInovice}
        setOpen={(val) => setOpenInovice(val)}
        billingDetails={billingDetails}
        invoice={invoice}
        invoiceId={id}
      />
      {(invoice?.status === 'PAST_DUE' || invoice?.status === 'BAD_DEBT' || invoice?.status === 'SENT') && (
        <PayInvoice open={openInovice} setOpen={setOpenInovice} invoiceDetails={invoice} />
      )}
    </div>
  );
}
