import axios from 'axios';
import { toast } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
const currentUrl = window.location.pathname;
const prefix = import.meta.env.VITE_API_PREFIX;
const tenant = import.meta.env.VITE_TENANT_NAME;
const axiosClient = axios.create();
axiosClient.defaults.baseURL = prefix;

axiosClient.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
};

axiosClient.interceptors.request.use(
  async (config) => {
    document.body.style.pointerEvents = 'none';
    document.body.style.opacity = '0.9';
    const authUserToken = JSON.parse(localStorage.getItem('auth'));
    if (authUserToken?.access_token) {
      const now = new Date();
      const refreshExpires = new Date(authUserToken?.refresh_expires_date);
      if (refreshExpires.getTime() - 432000 * 1000 < now.getTime()) {
        try {
          const response = await axios.post(`${prefix}/auth/client/refresh_tokens`, {
            refresh_token: authUserToken?.refresh_token,
          });
          const parsed = response.data;
          parsed.data.refresh_expires_date = new Date(new Date().getTime() + parsed?.data?.refresh_expires_in * 1000);
          localStorage.setItem('auth', JSON.stringify(parsed.data));
          config.headers['authorization'] = parsed?.data?.access_token;
        } catch (error) {
          Sentry.captureException(error);
          toast.error('Something went wrong');
        }
      } else {
        config.headers['authorization'] = authUserToken?.access_token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    document.body.style.pointerEvents = 'auto';
    document.body.style.opacity = '1';
    return response;
  },
  (error) => {
    document.body.style.pointerEvents = 'auto';
    document.body.style.opacity = '1';
    Sentry.captureException(error);
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem('auth');
      localStorage.setItem('lastUrl', currentUrl);
      if (error?.response?.data?.error?.type == 'Unauthorized') {
        return error?.response?.data?.error || error?.response?.data || 'Something went wrong';
      } else {
        window.location = '/signin';
      }
    } else if (error?.response?.status === 404 || error?.response?.status === 400) {
      return error?.response?.data?.error || error?.response?.data || 'Something went wrong';
    } else {
      return Promise.reject(error?.response?.data);
    }
  }
);
function loginValdiateEmailApi(data, tenant) {
  return post(`/auth/client/tokens?tenant=${tenant}&validate_email_only=true`, data, {
    transformResponse: [
      (result) => {
        const parsed = JSON.parse(result);
        return parsed;
      },
    ],
  });
}
function loginApi(data, tenant) {
  return post(`/auth/client/tokens?tenant=${tenant}`, data, {
    transformResponse: [
      (result) => {
        const parsed = JSON.parse(result);
        if (parsed?.data) {
          parsed.data.refresh_expires_date = new Date(new Date().getTime() + parsed?.data?.refresh_expires_in * 1000);
          localStorage.setItem('auth', JSON.stringify(parsed?.data));
        }
        return parsed;
      },
    ],
  });
}

function googleSigninApi(tenantName, code, redirect_url) {
  if (tenantName == 'laundrycare') {
    tenantName = 'Laundry care';
  } else if (tenantName == 'pushlaundry') {
    tenantName = 'Push laundry';
  }
  return post(`/auth/client//google/login?tenant_name=${tenantName}&redirect_url=${redirect_url}`, code, {
    transformResponse: [
      (result) => {
        let parsed = {};
        parsed.data = JSON.parse(result);
        if (parsed?.data) {
          parsed.data.refresh_expires_date = new Date(new Date().getTime() + parsed?.data?.refresh_expires_in * 1000);
          localStorage.setItem('auth', JSON.stringify(parsed?.data));
        }
        return parsed;
      },
    ],
  });
}

async function getRequest(URL) {
  const response = await axiosClient.get(`${URL}`);
  return response?.data || response;
}

async function postRequest(URL, payload) {
  const response = await axiosClient.post(`${URL}`, payload);
  if (response?.data) {
    return response?.data;
  } else {
    return response;
  }
}
async function getPostRequest(URL, payload = {}) {
  const response = await axiosClient.post(`${URL}`, payload);
  return response;
}
async function putRequest(URL, payload) {
  const response = await axiosClient.put(`${URL}`, payload);
  return response;
}

async function patchRequest(URL, payload) {
  const response = await axiosClient.patch(`${URL}`, payload);
  return response;
}

async function deleteRequest(URL) {
  const response = await axiosClient.delete(`${URL}`);
  return response;
}
const get = (url, params, config = {}) => axiosClient.get(url, { params, ...config });
const post = (url, data, config = {}) => axiosClient.post(url, data, config);

export {
  getRequest,
  postRequest,
  getPostRequest,
  putRequest,
  patchRequest,
  deleteRequest,
  loginValdiateEmailApi,
  loginApi,
  googleSigninApi,
};
