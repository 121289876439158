import axios from 'axios';
import { GET_ALL_PROVIDERS, GET_ALL_PROVIDERS_LOADING, SELECTED_PROVIDERS } from '../ActionsTypes';
import { getRequest, postRequest, getPostRequest, putRequest } from '../../utils/axios';
import { setAllServiceAddressesAction, setAllServiceAddressesLoadingAction } from '../Actions/ServiceAddressesActions';
import toast from 'react-hot-toast';
import { setAllProvidersAction, setAllProvidersLoadingAction } from '../Actions/ProvidersActions';
import moment from 'moment';
const initialState = {
  allProviders: [],
  allProvidersLoading: false,
  selectedProvider: null,
};
export const ProvidersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_PROVIDERS:
      return { ...state, allProviders: [...payload] };
    case GET_ALL_PROVIDERS_LOADING:
      return { ...state, allProvidersLoading: payload };
    case SELECTED_PROVIDERS:
      return { ...state, selectedProvider: payload };
    default:
      return state;
  }
};

export const getAllProvidersApi = async (id, payload = {}, type) => {
  try {
    const response_data = await getPostRequest(
      `/accounts/client/preferred_providers/${id}?pickup_times=1&type=${type}`,
      payload
    );
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getAllPreferredProviders = async (id, payload = {}) => {
  try {
    const response_data = await getPostRequest(`/accounts/client/preferred_providers/${id}?pickup_times=1`, payload);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const resetAllProvider = () => {
  return {
    type: GET_ALL_PROVIDERS,
    payload: [],
  };
};
export const resetSelectedProvider = () => {
  return {
    type: SELECTED_PROVIDERS,
    payload: null,
  };
};
