import React, { useEffect, useState } from 'react';
import { useForm, useController, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';
import PhoneNumbersList from '../../../components/PhoneNumbersList';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import { phoneTypes } from '../../../mockdata/common';
import { toast } from 'react-hot-toast';
import { updateProfileApi } from '../../../redux/Reducers/MyAccountReducer';
import ClientButton from '../../../components/ClientButton';
import { useDispatch } from 'react-redux';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { isEmpty } from 'lodash';
import Loader from '../../../components/Loader';
import {
  phone_add_plus_one,
  phone_mask_US_format,
  phone_mask_US_string,
  phone_remove_plus_one,
} from '../../../utils/commonUtil';
import { TrashIcon } from '@heroicons/react/24/outline';
import Button from '../../../components/Button';

const schema = yup.object({
  first_name: yup.string().required('Enter a valid name'),
  last_name: yup.string().required('Enter a valid name'),
  email: yup.string().email().required('Enter a valid email'),
  phone_number: yup.string().phone('US'),
});
const Checkboxes = ({ options, control, name, member }) => {
  const { field } = useController({
    control,
    name,
  });
  const addresses = member?.addresses?.map((item) => {
    return { service_address_id: item?.id?.toString() };
  });
  const [value, setValue] = React.useState(field.value || addresses);
  const onAddressChange = (e, id) => {
    const checked = e.target.checked;
    const valueCopy = value?.map((item) => {
      return { service_address_id: item?.service_address_id, operation: item?.operation || null };
    });
    const index = valueCopy.findIndex((item) => item?.service_address_id == id);
    const memberIndex = member?.addresses?.findIndex((item) => item?.id == id);
    if (checked) {
      if (memberIndex > -1) {
        valueCopy[index].operation = null;
      } else {
        valueCopy?.push({ service_address_id: id?.toString(), operation: 'CREATE' });
      }
    } else {
      if (memberIndex > -1) {
        valueCopy[index].operation = 'DELETE';
      } else {
        valueCopy[index].operation = null;
      }
    }
    field.onChange(valueCopy);
    setValue(valueCopy);
  };
  return (
    <>
      {options?.map((option, index) => (
        <label className='flex' key={index}>
          <input
            onChange={(e) => onAddressChange(e, option?.id)}
            key={option?.id}
            defaultChecked={value?.findIndex((item) => item?.service_address_id == option?.id) > -1}
            type='checkbox'
            value={option?.id}
            className='form-checkbox mt-1.5'
          />
          <span className='go-textblacknormal-16 ml-2 pt-0.5'>{option?.full_address}</span>
        </label>
      ))}
    </>
  );
};

export default function SingleMember({ member, deleteModalFn, data, setEdit }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, isTouched, errors, dirtyFields },
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const [editLoading, setEditLoading] = useState(false);
  const onSubmit = (newData, e) => {
    setEditLoading(true);
    const temp = { id: member?.id, operation: 'UPDATE' };
    if (newData?.first_name === member?.first_name) {
      delete temp?.first_name;
    } else {
      temp.first_name = newData?.first_name;
    }
    if (newData?.last_name === member?.last_name) {
      delete temp?.last_name;
    } else {
      temp.last_name = newData?.last_name;
    }
    if (newData?.email === member?.email) {
      delete temp?.email;
    } else {
      temp.email = newData?.email;
    }
    if (
      newData?.phone_number === member?.phone_numbers?.[0]?.phone_number &&
      newData?.phone_type === member?.phone_numbers?.[0]?.type
    ) {
      delete temp?.phone_number;
      delete temp?.phone_type;
    } else {
      temp.phone_number = {
        phone_number: phone_add_plus_one(newData?.phone_number) || member?.phone_numbers?.[0]?.phone_number,
        type: newData?.phone_type || member?.phone_numbers?.[0]?.type,
      };
    }
    if (newData?.service_addresses) {
      const newAddresses = newData?.service_addresses?.filter((item) => {
        if (item?.operation === 'CREATE' || item?.operation === 'DELETE') {
          return item;
        }
      });
      temp.service_addresses = newAddresses;
    }
    const payload = { members: [temp] };
    const create = updateProfileApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setEditLoading(false);
        toast.success('Member updated successfully!');
        setEdit(false);
        dispatch(getProfileDataApi(false));
      } else {
        setEditLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };

  return (
    <div className='col-span-full rounded-lg bg-white sm:col-span-6 xl:col-span-4'>
      <div className='h-full p-6'>
        {editLoading ? (
          <Loader text='processing...' classes=' h-96' />
        ) : (
          <div className='relative'>
            <div className='absolute -right-4 -top-4'>
              <DeleteIcon onClick={(e) => deleteModalFn(e, true, member)} />
            </div>
            <div className=' flex space-x-2'>
              <div className=' mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  First Name
                </label>
                <input
                  name='first_name'
                  className={`go-inputtext-16`}
                  {...register('first_name', { required: true })}
                  type='text'
                  defaultValue={member?.first_name}
                />
                {errors.first_name?.message && (
                  <p className='go-textnormal-14 text-red-500'>{errors.first_name?.message}</p>
                )}
              </div>
              <div className=' mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Last Name
                </label>
                <input
                  name='last_name'
                  className={`go-inputtext-16`}
                  {...register('last_name', { required: true })}
                  type='text'
                  defaultValue={member?.last_name}
                />
                {errors.last_name?.message && (
                  <p className='go-textnormal-14 text-red-500'>{errors.last_name?.message}</p>
                )}
              </div>
            </div>
            <div className=' mb-4 flex-row space-y-1.5'>
              <label className='go-label-16 mb-1 block' htmlFor='label'>
                Email
              </label>
              <input
                {...register('email', { required: true })}
                className={`go-inputtext-16 ${errors?.email?.type === 'required' ? 'border-red-500' : ''}`}
                type='email'
                defaultValue={member?.email}
              />
              {errors.email?.message && <p className='go-textnormal-14 text-red-500'>{errors.email?.message}</p>}
            </div>
            <div className=' mb-1 flex-row space-y-1.5'>
              <select
                {...register('phone_type', { required: true })}
                className='go-label-16 block w-full border-transparent p-0 focus:border-transparent focus:ring-0 '
                defaultValue={member?.phone_numbers?.[0]?.type}
              >
                {phoneTypes?.map((option) => {
                  return (
                    <option key={option?.id} value={option?.value}>
                      {option?.displayValue}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className=' mb-4 flex-row space-y-1.5'>
              <input
                {...register('phone_number', {
                  required: true,
                  message: 'Enter a valid phone number',
                })}
                className={`go-inputtext-16 ${errors?.phone_number?.type === 'required' ? 'border-red-500' : ''}`}
                type='tel'
                defaultValue={phone_remove_plus_one(member?.phone_numbers?.[0]?.phone_number)}
              />
              {errors.phone_number?.message && (
                <p className='go-textnormal-14 text-red-500'>{errors.phone_number?.message}</p>
              )}
            </div>
            <div className=' mb-4 flex-row space-y-1.5'>
              <label className='go-label-16  mb-1 block' htmlFor='label'>
                Service Address
              </label>
              <Checkboxes options={data?.addresses} control={control} name='service_addresses' member={member} />
            </div>
            {/* <div className='mt-5 flex cursor-pointer text-red-500'>
              <TrashIcon onClick={(e) => deleteModalFn(e, false, member)} className='h-5 w-5 cursor-pointer' />
              <span className='ml-2 font-medium'>Remove Member</span>
            </div> */}
          </div>
        )}
        <div className='flex space-x-4 p-5'>
          <Button variant='secondary' text='Cancel' classes='w-full' onClick={() => setEdit(false)} />
          <Button
            variant='primary-dark'
            text='Save'
            classes='w-full'
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}
