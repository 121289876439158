import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { getAllProvidersApi } from '../../../redux/Reducers/ProvidersReducer';
import Slider from 'react-slick';
import SingleProvider from './SingleProvider';
import Loader from '../../../components/Loader';
import ClientButton from '../../../components/ClientButton';
import { isEmpty } from 'lodash';
import { getPickupTimeApi } from '../../../redux/Reducers/CreateOrderReducer';
import { formatAndSortStartTimes } from '../../../utils/commonUtil';
import { dateFn, getTimeZoneAbbr, utcTimeToLocalTime } from '../../../utils/dateTimeFormatter';
import moment from 'moment-timezone';

export default function SelectProvider({ setstep }) {
  const { orderProvider, orderServiceAddress, selectedServices, orderType } = useSelector(
    (state) => state.createNewOrder
  );
  const { orderPreferences } = useSelector((state) => state.myaccount);
  const [avaiableProvidersLoading, setAvailableProvidersLoading] = useState(false);
  const [avaiableProviders, setAvailableProviders] = useState(null);
  const [providersList, setProvidersList] = useState([]);
  const [timeBlocks, setTimeBlocks] = useState(null);
  const convertDateTimeFormatFn = (schedules) => {
    const schedulesArray = Object.entries(schedules || {}).map(([key, value]) => {
      let temp = {
        date: dateFn('type4', key),
        dateWeekday: dateFn('type7', key),
        dateMonthDate: dateFn('type8', key),
        dateUnformatted: key,
        times: value?.map((item) => {
          return {
            start_time: utcTimeToLocalTime(`${key}T${item?.start_time}`, orderServiceAddress?.time_zone),
            end_time: utcTimeToLocalTime(`${key}T${item?.end_time}`, orderServiceAddress?.time_zone),
            default: {
              start_time: item?.start_time,
              end_time: item?.end_time,
            },
            dateUnformatted: key,
            time_zone: getTimeZoneAbbr(`${key}T${item?.start_time}`, orderServiceAddress?.time_zone),
          };
        }),
      };
      return temp;
    });
    return schedulesArray;
  };
  const providerListToDisplayFn = useCallback(
    (providers, timeBlocks) => {
      const newList = providers?.map((provider) => ({
        ...provider,
        schedules: convertDateTimeFormatFn(
          formatAndSortStartTimes(provider?.schedules, orderServiceAddress?.time_zone) || {}
        ),
        isPreferred: provider?.id === orderPreferences?.preferred_worker?.id,
      }));
      const index = newList?.findIndex((item) => item?.isPreferred);
      const preferredProvider = newList?.find((item) => item?.isPreferred);
      const temp = [...newList];
      if (preferredProvider) {
        temp.splice(index, 1);
        temp.unshift(preferredProvider);
      }
      temp.push({
        id: null,
        schedules: timeBlocks || {},
      });
      setProvidersList(temp);
    },
    [orderPreferences]
  );

  useEffect(() => {
    setAvailableProvidersLoading(true);
    const temp = {
      service_ids: selectedServices?.map((service) => service?.service_id) || [],
    };
    const data = getAllProvidersApi(orderServiceAddress?.id, temp, orderType == 'oneTime' ? 'ORDER' : 'subscription');
    data?.then((res) => {
      setAvailableProvidersLoading(false);
      if (res?.status === 200 || res?.status === 201) {
        setAvailableProviders(res?.data?.data || []);
      } else {
        setAvailableProviders([]);
      }
    });

    const response = getPickupTimeApi(null, orderServiceAddress?.id, orderType == 'oneTime' ? 'ORDER' : 'subscription');
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const sorted = formatAndSortStartTimes(res?.data || {}, orderServiceAddress?.time_zone);
        const data = convertDateTimeFormatFn(sorted || {});
        setTimeBlocks(data || {});
      }
    });
  }, [orderServiceAddress?.id, selectedServices]);
  useEffect(() => {
    avaiableProviders && timeBlocks && providerListToDisplayFn(avaiableProviders, timeBlocks);
  }, [avaiableProviders, timeBlocks]);

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='max-w-9xl mx-auto w-full'>
      {!isEmpty(avaiableProviders) && (
        <div className='flex items-center'>
          <p className=' text-client-text mb-2'>Best Matches In Your Neighborhood</p>
          {/* {orderPreferences?.preferred_service_address } */}
          {/* <p className=' text-client-text '>
            Best Matches at{' '}
            <span className='font-bold'>
              {orderServiceAddress?.full_address} ({moment.tz(orderServiceAddress?.time_zone).format('z')})
            </span>
          </p> */}
          {/* <p className=' text-client-text '>
            Best Matches In Your Neighborhood{' '}
            {postalCodeTimeZone(orderPreferences?.preferred_service_address?.postal_code)}
          </p> */}
        </div>
      )}

      <div className=''>
        {avaiableProvidersLoading ? (
          <Loader classes='h-96 w-full' bg={true} text='Search for providers' />
        ) : (
          <div className='w-full'>
            <Slider {...settings}>
              {providersList?.map(
                (provider, i) =>
                  provider && (
                    <SingleProvider
                      key={i}
                      provider={provider}
                      orderProvider={orderProvider}
                      allProviders={avaiableProviders}
                    />
                  )
              )}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
}
