import React, { useCallback, useEffect, useState } from 'react';
import Cards from './Cards';
import EditPaymentInfo from './EditPaymentInfo';
import GiftCard from './GiftCard';
import MyInvoices from './MyInvoices';
import { getBillingDetailsApi } from '../../../redux/Reducers/commonApis';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import DropdownSingleAddress from '../../../components/DropdownSingleAddress';
import EditIcon from '../../../components/Icons/EditIcon';
import Breadcrumb from '../../../components/Breadcrumb';
import EditBillingContactModal from './EditBillingContactModal';
import EditPaymentInfoModal from './EditPaymentInfoModal';
import GiftCardModal from './GiftCardModal';

export default function Payments({ setActiveTab, allLocations }) {
  const { selectedAddress, allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  console.log('selectedAddress :>> ', selectedAddress);
  const { profileData } = useSelector((state) => state.profile);
  const [editPayment, setEditPayment] = useState(false);
  const [editBilling, setEditBilling] = useState(false);
  const [editGiftCard, setEditGiftCard] = useState(false);
  const [billingDetails, setBillingDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const billingType = billingDetails?.addresses[0]?.billing_details?.default_payment_method_type;
  const getBillingDetailsFn = (id) => {
    setLoading(true);
    const response = getBillingDetailsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setBillingDetails(res?.data || {});
        setLoading(false);
      } else {
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    if (selectedAddress?.id) getBillingDetailsFn(selectedAddress?.id);
  }, [selectedAddress?.id]);

  return (
    <div className='sm:max-w-9xl w-full px-4 sm:mx-auto sm:px-6'>
      {/* {editPayment && (
        <div className='mb-4'>
          <ul className='inline-flex flex-wrap text-sm font-medium'>
            <li className='flex items-center'>
              <a
                className='go-link-16'
                href={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  setEditPayment(false);
                  setEditBilling(false);
                }}
              >
                Home
              </a>
              <svg className='text-client-link mx-1 h-4 w-4 fill-current' viewBox='0 0 16 16'>
                <path d='M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z' />
              </svg>
            </li>
            <li className='flex items-center'>
              <a
                className='go-link-16'
                href={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  setEditPayment(false);
                  setEditBilling(false);
                }}
              >
                Payments
              </a>
            </li>
          </ul>
        </div>
      )} */}
      {!loading &&
        profileData?.billing_type == 'SEPARATE' &&
        !isEmpty(allServiceAddresses) &&
        (profileData?.account_type == 'ORGANIZATION' || profileData?.account_type == 'ORGANIZATION_MEMBER') && (
          <div className='my-6 mt-3 flex w-full'>
            <div className='flex flex-col align-middle sm:flex-row sm:items-center'>
              <span className='go-textblackbold-16 mr-2'>Viewing info for:</span>
              <DropdownSingleAddress dropdownValues={allLocations} />
              {/* <button className='go-btn-primary ml-2 mt-1'>
                        <EditIcon onClick={() => setActiveTab('ServiceAddress')} />
                      </button> */}
            </div>
          </div>
        )}

      <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
        <Cards
          setEditPayment={setEditPayment}
          setEditBilling={setEditBilling}
          setEditGiftCard={setEditGiftCard}
          billingDetails={billingDetails}
          loading={loading}
        />
      </div>
      <div className='flex-row py-5'>
        <h3 className='go-textblackbold-24 py-5'>My Invoices</h3>
        <MyInvoices billingDetails={billingDetails} />
      </div>
      {/* {billingType == 'STRIPE' && <EditPaymentInfo editPayment={editPayment} setEditPayment={setEditPayment} />} */}
      {/* {editPayment && (
        <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
          {billingType == 'STRIPE' && <EditPaymentInfo editPayment={editPayment} setEditPayment={setEditPayment} />}
          <GiftCard
            setEditPayment={setEditPayment}
            billingDetails={billingDetails}
            reFetchBillingDetails={() => getBillingDetailsFn(selectedAddress?.id)}
          />
        </div>
      )} */}
      <GiftCardModal
        modalOpen={editGiftCard}
        setModalOpen={setEditGiftCard}
        billingDetails={billingDetails}
        reFetchBillingDetails={() => getBillingDetailsFn(selectedAddress?.id)}
      />

      <EditBillingContactModal
        modalOpen={editBilling}
        setModalOpen={setEditBilling}
        billingDetails={billingDetails}
        setEditBilling={setBillingDetails}
      />
      {billingType == 'STRIPE' && <EditPaymentInfoModal modalOpen={editPayment} setModalOpen={setEditPayment} />}
    </div>
  );
}
