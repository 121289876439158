import React, { useEffect, useState } from 'react';
import ModalWithHeading from '../../../components/ModalWithHeading';
import ClientButton from '../../../components/ClientButton';
import packageImg from '../../../images/package.png';
import splatterImg from '../../../images/splatter.png';
import warningImg from '../../../images/warning.png';
import washerImg from '../../../images/washer.png';
import windImg from '../../../images/wind.png';
import creditCardImg from '../../../images/credit-card.png';

import { Link } from 'react-router-dom';
export default function AgreementModal({ agreementModal, setAgreementModal, onacceptclick }) {
  const tenant = import.meta.env.VITE_TENANT_NAME;
  const [agreements, setAgreements] = useState([
    {
      id: 1,
      title: `I will package my laundry in 13-gallon (non-stretch) trash bags or Laundry Care’s reusable bags; otherwise, it will be measured in 13-gallon bags for accurate pricing.`,
      show: true,
      icon: packageImg,
      bold: false,
      lc: true,
      pl: false,
    },
    {
      id: 2,
      title: `I will have my laundry packaged in trash bags or other closable bag type prior to the driver’s arrival.`,
      show: true,
      icon: packageImg,
      bold: false,
      lc: false,
      pl: true,
    },
    {
      id: 3,
      title: `I understand that stain removal is not guaranteed.`,
      show: true,
      icon: splatterImg,
      bold: false,
      lc: true,
      pl: true,
    },
    {
      id: 4,
      title: `I agree to not include contaminated items (bugs, blood, urine or feces) with my order.`,
      show: true,
      icon: warningImg,
      bold: false,
      lc: true,
      pl: true,
    },
    {
      id: 5,
      title: `I understand that all items will be washed in a washer and dried in a dryer (we do not offer dry cleaning).`,
      show: true,
      icon: washerImg,
      bold: false,
      lc: true,
      pl: true,
    },
    {
      id: 6,
      title: `I understand that items requested to be air-dried may be damp upon delivery.`,
      show: true,
      icon: windImg,
      bold: false,
      lc: true,
      pl: true,
    },
    {
      id: 7,
      title: `A hold will be placed on your credit card for the estimated order amount. The actual charge will be processed upon delivery of the order.`,
      show: true,
      icon: creditCardImg,
      bold: true,
      lc: true,
      pl: true,
    },
  ]);
  const onCheckboxChange = (e, id) => {
    const newAgreements = agreements.map((agreement) => {
      if (agreement?.id === id) {
        agreement.selected = e.target.checked;
      }
      return agreement;
    });
    setAgreements(newAgreements);
  };

  return (
    <ModalWithHeading
      modalOpen={agreementModal}
      setModalOpen={setAgreementModal}
      title='Service Agreements'
      classes='max-w-3xl'
    >
      <div className='mx-2 sm:mx-8'>
        <Link className='flex justify-center text-center font-semibold text-blue-400 underline' to='/tos'>
          <span>
            Read the complete Terms of Service <span className=' italic'>here</span>
          </span>
        </Link>
        <div className='flex-row items-start '>
          {agreements?.map(
            (agreement, index) =>
              tenant === 'laundrycare' &&
              agreement?.lc && (
                <div
                  className='items-center justify-between space-y-4 space-x-2 align-middle md:flex md:space-y-0'
                  key={index}
                >
                  <div className='flex items-start space-x-2 space-y-3 sm:space-y-6 sm:space-x-8'>
                    <div className='mt-6 flex w-12 shrink-0 align-middle'>
                      <img className='w-12' src={agreement?.icon} />
                    </div>
                    <div className='flex align-middle'>
                      <div className={`text-sm sm:text-base ${agreement?.bold && 'font-semibold'}`}>
                        {agreement?.title}
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
          {agreements?.map(
            (agreement, index) =>
              tenant === 'pushlaundry' &&
              agreement?.pl && (
                <div
                  className='items-center justify-between space-y-4 space-x-2 align-middle md:flex md:space-y-0'
                  key={index}
                >
                  <div className='flex items-start space-x-2 space-y-3 sm:space-y-6 sm:space-x-8'>
                    <div className='mt-6 flex w-12 shrink-0 align-middle'>
                      <img className='w-12' src={agreement?.icon} />
                    </div>
                    <div className='flex align-middle'>
                      <div className={`text-sm sm:text-base ${agreement?.bold && 'font-semibold'}`}>
                        {agreement?.title}
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>

        <div className='mb-10 flex justify-center py-5'>
          <ClientButton
            variant='secondary'
            text='Decline'
            classes=''
            onClick={(e) => {
              setAgreementModal(false);
            }}
          />
          <ClientButton
            variant='primary'
            text='Accept'
            // disabled={agreements?.filter((agreement) => agreement?.selected).length < 7}
            classes=''
            onClick={(e) => {
              onacceptclick(e, true);
            }}
          />
        </div>
      </div>
    </ModalWithHeading>
  );
}
