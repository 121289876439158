import React, { useEffect, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { addGiftCardApi, getBillingDetailsApi } from '../../../redux/Reducers/commonApis';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import Stripe from '../../../components/Stripe';
import Loader from '../../../components/Loader';
import { amountCheckFn, textCheckFn } from '../../../utils/commonUtil';
import toast from 'react-hot-toast';
export default function PaymentMethodAndPreferences({ services, addService, availableServices }) {
  const dispatch = useDispatch();
  const { orderPromoCode, orderSummaryError, orderServiceAddress } = useSelector((state) => state.createNewOrder);
  const [paymentAvailableType, setPaymentAvailableType] = useState(false);
  const [paymentAvailable, setPaymentAvailable] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [promoCode, setPromoCode] = useState(orderPromoCode || '');
  const [billingDetails, setBillingDetails] = useState({});
  const [giftCardBalance, setGiftCardBalance] = useState(null);
  const [giftCardNumber, setGiftCardNumber] = useState(null);
  const [giftCardErrorMsg, setGiftCardErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    promoCode === '' && applyPromoCode();
  }, [promoCode]);

  const applyPromoCode = () => {
    dispatch(setOrderDataAction({ orderPromoCode: promoCode }));
  };
  const getBillingDetailsFn = (id) => {
    setLoading(true);
    const response = getBillingDetailsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const details = res?.data?.addresses?.find((item) => item?.id === id);
        dispatch(
          setOrderDataAction({
            orderPaymentDetails: details || null,
          })
        );
        setBillingDetails(details || {});
        setPaymentAvailableType(details?.billing_details?.default_payment_method_type || null);
        setPaymentAvailable(details?.billing_details?.default_payment_method || null);
        setLoading(false);
        setGiftCardBalance(res?.data?.available_gift_card_balance || null);
      } else {
        setPaymentAvailableType(null);
        setPaymentAvailable(null);
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };
  const addNewGiftCardFn = () => {
    if (!giftCardNumber) {
      toast.error('Please enter gift card number');
      setGiftCardErrorMsg('Please enter gift card number');
      return;
    }
    setLoading(true);
    const data = {
      gift_card_code: giftCardNumber,
    };
    const response = addGiftCardApi(data);
    response.then((res) => {
      setGiftCardErrorMsg(null);
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.message || 'Gift card added successfully');
        setGiftCardNumber(null);
        // getGiftCardFn();
        setLoading(false);
        getBillingDetailsFn(orderServiceAddress?.id);
      } else {
        toast.error(res?.message || 'Something went wrong');
        setGiftCardErrorMsg(res?.message || 'Invalid gift card number');
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    if (orderServiceAddress?.id) getBillingDetailsFn(orderServiceAddress?.id);
  }, [orderServiceAddress?.id]);

  return (
    <div className='z-0 col-span-full flex flex-col sm:col-span-8'>
      {/* <header className='flex items-center pb-3'>
        <h5 className='go-heading-18'>Confirm Payment Method</h5>
      </header>
      <hr className='go-hr-dashed '></hr> */}

      <div className='rounded-lg  bg-white p-6 shadow-lg'>
        {loading ? (
          <Loader text='loading payment details' classes='h-32' />
        ) : (
          <div>
            <div className='grid gap-0 xl:grid-cols-2'>
              {paymentAvailableType == 'STRIPE' && paymentAvailable && !addNewCard ? (
                <div className='py-2 sm:py-4'>
                  <label htmlFor='paymentlabel' className='go-label-16'>
                    Payment Method
                  </label>
                  <input
                    id='paymentlabel'
                    className='form-input bg-client-inputdisabledbg mt-2 w-full border-0 font-medium '
                    type='text'
                    defaultValue={`${titleCaseFormatter(
                      billingDetails?.billing_details?.default_payment_method?.card_brand
                    )} **** **** **** ${billingDetails?.billing_details?.default_payment_method?.card_last4}`}
                    disabled
                  />
                </div>
              ) : paymentAvailableType == 'ACH' || paymentAvailableType == 'CHECK' ? (
                <div className='py-2 sm:py-4'>
                  <label htmlFor='paymentlabel' className='go-label-16'>
                    Payment Method
                  </label>
                  <p htmlFor='paymentlabel' className='go-heading-18'>
                    {textCheckFn(paymentAvailableType)}
                  </p>
                </div>
              ) : (
                <div className='py-2 sm:py-4'>
                  {!addNewCard && (
                    <p htmlFor='paymentlabel' className='go-heading-18'>
                      You don't have a payment method.
                    </p>
                  )}

                  {addNewCard && (
                    <div className=''>
                      <Stripe
                        addressid={orderServiceAddress?.id}
                        cardAdded={() => {
                          getBillingDetailsFn(orderServiceAddress?.id);
                          setAddNewCard(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className=' flex justify-center py-2 sm:items-end sm:justify-end sm:py-4'>
                {!addNewCard && paymentAvailableType != 'ACH' && paymentAvailableType != 'CHECK' && (
                  <ClientButton
                    variant='primary'
                    text={
                      paymentAvailableType == 'STRIPE'
                        ? !paymentAvailable
                          ? 'Add a credit card'
                          : 'Update Credit Card'
                        : 'Add a credit card'
                    }
                    classes='mr-2'
                    onClick={() => setAddNewCard(true)}
                  />
                )}
              </div>
            </div>
            {giftCardBalance > 0 && (
              <div className='flex'>
                <div className='py-2 sm:py-4'>
                  <label htmlFor='giftcardlabel' className='go-label-16'>
                    Gift Card Balance
                  </label>
                  <input
                    id='giftcardlabel'
                    className='form-input bg-client-inputdisabledbg mt-2 w-full border-0 font-medium '
                    type='text'
                    defaultValue={amountCheckFn(giftCardBalance)}
                    disabled
                  />
                </div>
              </div>
            )}
            <div className='mt-5 w-full'>
              <label className='go-label-16 mb-1 block' htmlFor='label'>
                Add a Gift Card
              </label>
              <input
                id='label'
                className='go-inputtext-16 sm:w-80 '
                type='text'
                defaultValue={giftCardNumber}
                onChange={(e) => setGiftCardNumber(e.target.value)}
              />
              {/* {orderSummaryError && <span className='text-red-500'>{orderSummaryError}</span>} */}
              <div className='flex justify-center sm:justify-start'>
                <ClientButton
                  size='small'
                  variant='primary'
                  text='Save'
                  onClick={() => addNewGiftCardFn()}
                  classes='mt-2 w-40'
                />
              </div>
              {giftCardErrorMsg && <div className='text-red-500'>{giftCardErrorMsg}</div>}
            </div>
            <div className='mt-5 w-full'>
              <label className='go-label-16 mb-1 block' htmlFor='label'>
                Promo Code
              </label>
              <input
                id='label'
                className='go-inputtext-16 sm:w-80 '
                type='text'
                defaultValue={promoCode}
                onChange={(e) => setPromoCode(e?.target?.value)}
              />
              {/* {orderSummaryError && <span className='text-red-500'>{orderSummaryError}</span>} */}
              <div className='flex justify-center sm:justify-start'>
                <ClientButton
                  size='small'
                  variant='primary'
                  text='Apply'
                  onClick={() => applyPromoCode()}
                  classes='mt-2 w-40'
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
