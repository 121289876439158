import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceAddressModal from '../pages/CreateNewOrder/PickupAddress/ServiceAddressModal';

export default function AddAddressActionBar() {
  const { allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  const [newAddressModalOpen, setNewAddressModalOpen] = useState(false);
  return (
    <>
      {isEmpty(allServiceAddresses) && (
        <div className='mx-auto flex w-full space-x-1 bg-white py-2'>
          <div className='w-full border-yellow-400 bg-yellow-50 p-4'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <ExclamationTriangleIcon className='h-5 w-5 text-yellow-400' aria-hidden='true' />
              </div>
              <div className='ml-3'>
                <p className='text-sm text-yellow-700'>
                  Let’s get started!{' '}
                  <a
                    href='#'
                    onClick={() => setNewAddressModalOpen(true)}
                    className='font-medium text-yellow-700 underline hover:text-yellow-600'
                  >
                    Add your service address
                  </a>{' '}
                  to see pricing, promotions, and schedule your first pickup.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <ServiceAddressModal
        newAddressModalOpen={newAddressModalOpen}
        setNewAddressModalOpen={(val) => setNewAddressModalOpen(val)}
        addressModalType={'new'}
      />
    </>
  );
}
