import React, { useState, useEffect } from 'react';
import subscriptionsList from '../../../mockdata/subscriptions';
import SubscriptionsDefault from './SubscriptionsDefault';
import SubscriptionsCollapse from './SubscriptionsCollapse';
import DeactivateModal from './DeactivateModal';
import { useSelector } from 'react-redux';
import { getActiveSubscriptionsApi } from '../../../redux/Reducers/commonApis';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import { toast } from 'react-hot-toast';
import DropdownSingleAddress from '../../../components/DropdownSingleAddress';
import EditIcon from '../../../components/Icons/EditIcon';
import { isEmpty } from 'lodash';
function Subscriptions({ setActiveTab, allLocations }) {
  const { selectedAddress, allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  const [reloadSubscriptions, setReloadSubscriptions] = useState(false);
  const [data, setData] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const getActiveSubscriptionsFn = (filters) => {
    setLoading(true);
    const response = getActiveSubscriptionsApi(filters);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setData(res?.data?.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };
  const deactivateModalFn = (val, id) => {
    if (id) {
      setDeactivateModal(val);
      setSelectedSubscription(id);
    }
  };
  useEffect(() => {
    const temp = {
      filter: [
        {
          columns: ['customer_address_id'],
          value: [`${selectedAddress?.id}`],
          operation: '=',
        },
      ],
    };
    getActiveSubscriptionsFn(temp);
  }, [selectedAddress, reloadSubscriptions]);
  return (
    <div className='sm:max-w-9xl w-full px-4 sm:mx-auto sm:px-6'>
      {/* {(profileData?.account_type == 'ORGANIZATION' || profileData?.account_type == 'ORGANIZATION_MEMBER') && ( */}
      {!isEmpty(allServiceAddresses) && (
        <div className='my-6 mt-3 flex w-full'>
          <div className='flex flex-col align-middle sm:flex-row sm:items-center'>
            <span className='go-textblackbold-16 mr-2'>Viewing info for:</span>
            <DropdownSingleAddress dropdownValues={allLocations} newAddress={(e) => newAddressFn(e)} />
            {/* <button className='go-btn-primary ml-2 mt-1'>
            <EditIcon onClick={() => setActiveTab('ServiceAddress')} />
          </button> */}
          </div>
        </div>
      )}

      {/* )} */}
      <div className=''>
        {loading ? (
          <Loader text='loading active subscriptions...' classes='h-96' />
        ) : data?.length == 0 ? (
          <NoData text='No subscriptions found' classes='h-96' />
        ) : data && data?.length > 0 ? (
          <SubscriptionsCollapse list={data} deactivateModalFn={(val, id) => deactivateModalFn(val, id)} />
        ) : (
          <SubscriptionsDefault />
        )}
        <DeactivateModal
          deactivateModal={deactivateModal}
          setDeactivateModal={setDeactivateModal}
          selectedSubscription={selectedSubscription}
          setReloadSubscriptions={setReloadSubscriptions}
        />
      </div>
    </div>
  );
}

export default Subscriptions;
