import React, { useRef, useState } from 'react';
import ClientButton from '../../components/ClientButton';
import { addNewServiceAddressesApi, getAllServiceAddressesApi } from '../../redux/Reducers/ServiceAddressesReducer';
import { useDispatch } from 'react-redux';
import calendar_icon from '../../icons/icons-checkmark-round-green.svg';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { AddressAutofill } from '@mapbox/search-js-react';
import { isNull } from 'lodash';
import partyPopper from '../../images/party-popper.svg';
import Loader from '../../components/Loader';
import ModalBasic from '../../components/Modal';
import { phoneTypes } from '../../mockdata/common';
import { phone_add_plus_one, phone_remove_plus_one } from '../../utils/commonUtil';
import { useNavigate } from 'react-router-dom';
import { getProfileDataApi } from '../../redux/Reducers/ProfileReducer';
import { updateProfileApi } from '../../redux/Reducers/MyAccountReducer';
import CheckmarkIcon from '../../components/Icons/CheckmarkIcon';
const MAPBOX_API_TOKEN =
  import.meta.env.VITE_TENANT_NAME === 'laundrycare'
    ? import.meta.env.VITE_LAUNDRYCARE_MAPBOX_API_TOKEN
    : import.meta.env.VITE_TENANT_NAME === 'pushlaundry'
    ? import.meta.env.VITE_PUSHLAUNDRY_MAPBOX_API_TOKEN
    : '';
const tenant = import.meta.env.VITE_TENANT_NAME;
export default function WelcomeModalNewAddress({ cancel = false, setModalOpen, modalOpen, data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameField = useRef(null);
  const address1Field = useRef(null);
  const address2Field = useRef(null);
  const cityField = useRef(null);
  const stateField = useRef(null);
  const zipField = useRef(null);
  const [phone, setPhone] = useState({
    type: 'MOBILE',
    phone_number: '',
  });
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [addressData, setAddressData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressAdded, setAddressAdded] = useState(false);
  const addressChangeFn = (data) => {
    setAddressData((prev) => ({
      ...prev,
      address_line_1: data.features[0].properties.address_line1,
      address_line_2: data.features[0].properties.address_line2,
      city: data.features[0].properties.address_level2,
      state: data.features[0].properties.address_level1,
      postal_code: data.features[0].properties.postcode,
    }));
  };

  const validateAddressFn = (address) => {
    const fields = ['name', 'address_line_1', 'city', 'state', 'postal_code'];
    for (const field of fields) {
      if (!address[field] || address[field] === '') {
        toast.error(`Please enter a ${field.replace('_', ' ')}`);
        setAddressData((prev) => ({ ...prev, [field]: null }));
        return false;
      }
    }
    return true;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPhone((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmitAddress = (e) => {
    e.preventDefault();
    const payload = {
      name: nameField.current.value,
      address_line_1: address1Field.current.value,
      address_line_2: address2Field.current.value,
      city: cityField.current.value,
      state: stateField.current.value,
      postal_code: zipField.current.value,
    };
    if (validateAddressFn(payload)) {
      if (phone?.phone_number == '') {
        setPhoneNumberError(true);
      } else {
        setPhoneNumberError(false);
        setLoading(true);
        const create = dispatch(addNewServiceAddressesApi(payload));
        create.then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            updatePhoneNumberApi({
              phone_number: {
                phone_number: phone_add_plus_one(phone?.phone_number),
                type: phone.type,
              },
            });
          } else {
            setLoading(false);
            if (res?.message == `The address ${addressData?.name} does not fall in any active service area.`) {
              toast.error(
                'The address you entered is currently outside our active service areas. Please double-check your address or contact our support team for assistance.'
              );
            } else {
              toast.error(res?.message || 'Something went wrong');
            }
          }
        });
      }
    }
  };

  const updatePhoneNumberApi = (payload) => {
    const create = updateProfileApi(payload);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setLoading(false);
        onCancel();
        setAddressAdded(true);
        dispatch(getAllServiceAddressesApi());
        toast.success(res?.error?.message || 'New address added successfully');
        dispatch(getProfileDataApi(false));
      } else {
      }
    });
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className='py-2 sm:px-5 sm:py-4'>
        <ModalBasic modalOpen={modalOpen} setModalOpen={setModalOpen} classes='max-w-2xl'>
          {loading ? (
            <Loader text='processing...' classes='h-40' />
          ) : !addressAdded ? (
            <>
              <div className='flex'>
                <div className='mx-6'>
                  <div className='mb-2 flex'>
                    <img src={partyPopper} alt='sort none' className='mr-2 h-6 w-6' />
                    Welcome, {data?.first_name}! Just one more step to setup your account.
                  </div>
                  <p>
                    Enter your address and phone number so we can show you accurate pricing and services available in
                    your area.
                  </p>
                </div>
              </div>
              <div className='m-auto  mt-5 max-w-5xl rounded-md  bg-white p-5'>
                <form onSubmit={handleSubmitAddress}>
                  <p className='go-textblacknormal-16'>Enter Your Address</p>
                  <div className='grid gap-5 pt-4 pb-10 md:grid-cols-3'>
                    <div>
                      <div>
                        <label className='go-label-16 mb-2 block' htmlFor='name'>
                          Label
                        </label>
                        <input
                          ref={nameField}
                          name='name'
                          className={`go-inputtext-16 ${isNull(addressData?.name) ? 'border-red-500' : ''}`}
                          type='text'
                          defaultValue={addressData?.name}
                          onChange={(e) => {
                            setAddressData((prev) => ({ ...prev, name: e.target.value }));
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='go-label-16 mb-2 block' htmlFor='address_line_1'>
                          Street Address
                        </label>
                        <AddressAutofill
                          accessToken={MAPBOX_API_TOKEN}
                          options={{
                            language: 'en',
                            country: 'US',
                          }}
                          onRetrieve={addressChangeFn}
                        >
                          <input
                            ref={address1Field}
                            name='address_line_1'
                            className={`go-inputtext-16 ${isNull(addressData?.address_line_1) ? 'border-red-500' : ''}`}
                            type='text'
                            placeholder='Address'
                            autoComplete='address-line1'
                            defaultValue={addressData?.address_line_1}
                          />
                        </AddressAutofill>
                      </div>
                    </div>

                    <div>
                      <div>
                        <label className='go-label-16 mb-2 block' htmlFor='address_line_2'>
                          Apartment or suite number
                        </label>
                        <input
                          ref={address2Field}
                          name='address_line_2'
                          className={`go-inputtext-16`}
                          type='text'
                          placeholder='Apartment number'
                          autoComplete='address-line2'
                          defaultValue={addressData?.address_line_2}
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='go-label-16 mb-2 block' htmlFor='city'>
                          City
                        </label>
                        <input
                          ref={cityField}
                          name='city'
                          className={`go-inputtext-16 ${isNull(addressData?.city) ? 'border-red-500' : ''}`}
                          type='text'
                          placeholder='City'
                          autoComplete='address-level2'
                          defaultValue={addressData?.city}
                        />
                      </div>
                    </div>
                    <div className='flex space-x-2'>
                      <div className='flex-1'>
                        <div>
                          <label className='go-label-16 mb-2 block' htmlFor='state'>
                            State
                          </label>
                          <input
                            ref={stateField}
                            name='state'
                            className={`go-inputtext-16 ${isNull(addressData?.state) ? 'border-red-500' : ''}`}
                            type='text'
                            placeholder='State'
                            autoComplete='address-level1'
                            defaultValue={addressData?.state}
                          />
                        </div>
                      </div>
                      <div className='flex-1'>
                        <div>
                          <label className='go-label-16 mb-2 block' htmlFor='postal_code'>
                            Zip Code
                          </label>
                          <input
                            ref={zipField}
                            name='postal_code'
                            className={`go-inputtext-16 ${isNull(addressData?.postal_code) ? 'border-red-500' : ''}`}
                            type='number'
                            placeholder='Postcode'
                            autoComplete='postal-code'
                            defaultValue={addressData?.postal_code}
                            maxLength={5}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='go-textblacknormal-16'>Enter Your Phone Number</p>{' '}
                  <div className='grid gap-5 pt-4 md:grid-cols-3'>
                    <div className='relative mb-4 flex-row space-y-1.5'>
                      <select
                        className='go-label-16 block w-full border-transparent p-0 focus:border-transparent focus:ring-0 '
                        id='type'
                        name='type'
                        value={phone.type}
                        onChange={handleChange}
                      >
                        {phoneTypes?.map((option) => {
                          return (
                            <option key={option?.id} value={option?.value}>
                              {option?.displayValue}
                            </option>
                          );
                        })}
                      </select>
                      <label className='go-label-16 mb-1 hidden' htmlFor='label'>
                        Phone Number
                      </label>
                      <input
                        type='tel'
                        className={`go-inputtext-16`}
                        id='phone_number'
                        name='phone_number'
                        pattern='\d{10}'
                        defaultValue={phone_remove_plus_one(phone.phone_number)}
                        onChange={handleChange}
                      />
                      {phoneNumberError && <p className='text-red-500'>Please enter a valid phone number</p>}
                    </div>
                  </div>
                  <div className='flex py-4'>
                    <ClientButton variant='primary' text='Save' classes='mr-2' type='submit' />
                    <ClientButton
                      variant='secondary'
                      text='Cancel'
                      classes='mr-2'
                      onClick={(e) => {
                        onCancel();
                      }}
                    />
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className='flex'>
                <div className='mx-6 '>
                  <div className='mb-2 flex'>
                    <img src={calendar_icon} alt='tick' className='mr-2 h-5 w-5' />
                    All done!
                  </div>
                  <p>You're ready to go. Create your first laundry order today!</p>
                </div>
              </div>
              <div className='m-auto  mt-5 max-w-5xl rounded-md  bg-white p-5'>
                <div>
                  <ClientButton
                    variant='primary'
                    text='Create Order'
                    classes='mr-2'
                    onClick={() => navigate('/create-new-order')}
                  />
                </div>
              </div>
            </>
          )}
        </ModalBasic>
      </div>
    </>
  );
}
