import React, { useCallback, useEffect, useState } from 'react';
import AccountInfo from './AccountInfo';
import MyUser from './MyUser';
import EditMembers from './EditMember';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import ServiceAddressesCard from './ServiceAddressesCard';
import ServiceAddressesTable from './ServiceAddressesTable';
import MemberCard from './MemberCard';
import MembersTable from './MembersTable';
import EditAccountModal from './EditAccountModal';
import EditMyUserModal from './EditMyUserModal';
import ServiceAddressModal from '../../CreateNewOrder/PickupAddress/ServiceAddressModal';
import AddMemberModal from './AddMemberModal';
import EditMemberModal from './EditMemberModal';

export default function Profile({ setActiveTab }) {
  const { profileData } = useSelector((state) => state.profile);
  const { profileLoading } = useSelector((state) => state.profile);
  const { allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  const [addMember, setAddMember] = useState(false);
  const [editAllMembers, setEditAllMembers] = useState(false);
  const [editMemberModalOpen, setEditMemberModalOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [editUser, setEditUser] = useState(false);
  const [editAccountInfo, setEditAccountInfo] = useState(false);
  const [newAddressModalOpen, setNewAddressModalOpen] = useState(false);
  const [editAddressId, setEditAddressId] = useState(null);

  useEffect(() => {
    !newAddressModalOpen && setEditAddressId(null);
  }, [newAddressModalOpen]);

  useEffect(() => {
    !editMemberModalOpen && setSelectedMemberId(null);
  }, [editMemberModalOpen]);
  return (
    <>
      {profileLoading ? (
        <Loader text='loading profile...' classes='h-80' />
      ) : (
        <div className='sm:max-w-9xl w-full px-4 sm:mx-auto sm:px-6'>
          {editAllMembers ? (
            <EditMembers
              data={profileData || {}}
              add={addMember}
              setAdd={(val) => setAddMember(val)}
              setEdit={(val) => setEditAllMembers(val)}
            />
          ) : (
            <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
              <React.Fragment>
                <AccountInfo setEdit={(val) => setEditAccountInfo(val)} data={profileData || {}} />
                <MyUser edit={editUser} setEdit={(val) => setEditUser(val)} data={profileData || {}} />
                {profileData?.account_type == 'ORGANIZATION' && profileData?.members?.length <= 1 && (
                  <MemberCard
                    data={profileData || {}}
                    edit={editAllMembers}
                    setEdit={(val) => setEditAllMembers(val)}
                    setAddMember={(val) => setAddMember(val)}
                  />
                )}
                {allServiceAddresses?.length <= 1 && (
                  <ServiceAddressesCard
                    addressess={allServiceAddresses}
                    setNewAddressModalOpen={setNewAddressModalOpen}
                    selectedAddressId={(val) => setEditAddressId(val)}
                  />
                )}
              </React.Fragment>
            </div>
          )}
          {allServiceAddresses?.length > 1 && !editAllMembers && (
            <ServiceAddressesTable
              addressess={allServiceAddresses}
              setNewAddressModalOpen={setNewAddressModalOpen}
              setActiveTab={setActiveTab}
              selectedAddressId={(val) => setEditAddressId(val)}
            />
          )}
          {profileData?.members?.length > 1 && !editAllMembers && (
            <MembersTable
              members={profileData?.members}
              setNewAddressModalOpen={setAddMember}
              setActiveTab={setActiveTab}
              setEdit={(val) => setEditAllMembers(val)}
              setEditMemberModalOpen={(val) => setEditMemberModalOpen(val)}
              setSelectedMemberId={(val) => setSelectedMemberId(val)}
            />
          )}
          <EditAccountModal
            modalOpen={editAccountInfo}
            setModalOpen={(val) => setEditAccountInfo(val)}
            data={profileData || {}}
          />
          <EditMyUserModal modalOpen={editUser} setModalOpen={(val) => setEditUser(val)} data={profileData || {}} />
          <ServiceAddressModal
            newAddressModalOpen={newAddressModalOpen}
            setNewAddressModalOpen={(val) => setNewAddressModalOpen(val)}
            addressModalType={editAddressId ? 'edit' : 'new'}
            selectedAddressId={editAddressId}
          />
          <AddMemberModal modalOpen={addMember} setModalOpen={(val) => setAddMember(val)} data={profileData || {}} />
          <EditMemberModal
            modalOpen={editMemberModalOpen}
            setModalOpen={(val) => setEditMemberModalOpen(val)}
            data={profileData}
            member={profileData?.members?.find((member) => member.id === selectedMemberId) || {}}
          />
        </div>
      )}
    </>
  );
}
