import React, { useCallback } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import avatar from '../../../images/avatar.png';
import ClientButton from '../../../components/ClientButton';
import no_picture from '../../../images/no-profile-picture.png';
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { isEmpty } from 'lodash';
import OrgNotifications from './OrgNotifications';
import IndividualNotifications from './IndividualNotifications';

export default function Cards({ providersModal, setProvidersModal, setOrderPreference }) {
  const { profileData } = useSelector((state) => state.profile);
  const { allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  const { selectedProvider } = useSelector((state) => state.providers);
  const { orderPreferences, preferencesLoading } = useSelector((state) => state.myaccount);
  const preferencesArray = orderPreferences?.preferences?.split(', ');
  const onProviderModelOpen = useCallback(
    (e) => {
      e.stopPropagation();
      setProvidersModal(true);
    },
    [providersModal]
  );
  return (
    <>
      <div className=''>
        <div className='z-0 rounded-lg bg-white '>
          <header className='flex items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Order Preferences</h5>
            {!isEmpty(allServiceAddresses) && (
              <div className='flex'>
                <button className='go-btn-primary mr-2'>
                  <EditIcon
                    onClick={() => {
                      setOrderPreference(true);
                    }}
                  />
                </button>
              </div>
            )}
          </header>
          <div className='p-6'>
            <div className='mb-4 flex-row space-y-1.5'>
              {preferencesLoading ? (
                <Loader text='loading preferences...' classes='h-24' />
              ) : orderPreferences?.preferences ? (
                preferencesArray?.map((preference, index) => (
                  <div key={index} className=''>
                    {preference.includes(':') ? (
                      <>
                        <span className='bodyText opacity-30 '>{preference.split(':')[0]}</span>
                        <span className='bodyText text-black'>{preference.split(':')[1]}</span>
                      </>
                    ) : (
                      <span>{preference}</span>
                    )}
                  </div>
                ))
              ) : (
                <div className='go-label-16'>No preferences set</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='z-0 rounded-lg bg-white '>
          <header className='flex items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Preferred Provider</h5>
            {orderPreferences?.preferred_worker && (
              <div className='flex'>
                <button className='go-btn-primary mr-2'>
                  <EditIcon
                    onClick={(e) => {
                      onProviderModelOpen(e);
                    }}
                  />
                </button>
              </div>
            )}
          </header>
          <div className='py-6'>
            {preferencesLoading ? (
              <Loader text='loading provider...' classes='h-24' />
            ) : !orderPreferences?.preferred_worker ? (
              <div className='flex px-6'>
                <div className='mb-4 flex-row space-y-1.5'>
                  <div className='go-label-16 pb-4'>You have no Preferred Provider</div>
                  {!isEmpty(allServiceAddresses) && (
                    <ClientButton
                      variant='secondary'
                      text='Select Provider'
                      size='small'
                      onClick={(e) => onProviderModelOpen(e)}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className='flex justify-between px-6 pt-6'>
                <div className='mb-4 flex-row space-y-1.5'>
                  <div className='go-label-16'>Name</div>
                  <div className='go-textblacknormal-16 font-medium'>
                    {orderPreferences?.preferred_worker?.first_name} {orderPreferences?.preferred_worker?.last_name}
                  </div>
                </div>
                <div className='mb-4 flex-row space-y-1.5'>
                  <img
                    className={' h-16 w-16 rounded-full'}
                    src={
                      orderPreferences?.preferred_worker?.profile_image?.absolute_url
                        ? orderPreferences?.preferred_worker?.profile_image?.absolute_url
                        : no_picture
                    }
                    alt={orderPreferences?.preferred_worker?.first_name}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {profileData?.account_type == 'ORGANIZATION' || profileData?.account_type == 'ORGANIZATION_MEMBER' ? (
        <OrgNotifications accountType={profileData?.account_type} />
      ) : (
        <IndividualNotifications accountType={profileData?.account_type} />
      )}
      {/* <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Service Addresses</h5>
          <div className='flex'>
            <button className='go-btn-primary mr-2'>
              <EditIcon
                onClick={() => {
                  setEditBilling(true);
                }}
              />
            </button>
          </div>
        </header>
        <div className='flex px-6 pt-6'>
          <div className='mb-4 w-1/2 flex-col space-y-1.5'>
            <div className='go-label-16'>Name</div>
            <div className='go-textblacknormal-16 font-medium'>Matt Barnes</div>
          </div>
          <div className='mb-4 w-1/2 flex-col space-y-1.5'>
            <div className='go-label-16'>Mobile</div>
            <div className='go-textblacknormal-16 font-medium'>614-555-1111</div>
          </div>
        </div>
        <div className=' px-6 pb-6'>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Email</div>
            <div className='go-textblacknormal-16 font-medium'>mbarnes@abcco.com</div>
          </div>
        </div>
      </div> */}
    </>
  );
}
