import React, { useCallback, useEffect, useState } from 'react';
import NoData from '../../../components/NoData';
import { useNavigate } from 'react-router-dom';
import StatusChip from '../../../components/StatusChip';
import { dateFn } from '../../../utils/dateTimeFormatter';
import Loader from '../../../components/Loader';
import { getMyInvoicesApi } from '../../../redux/Reducers/PaymentsReducer';
import ClientButton from '../../../components/ClientButton';
import PayInvoice from '../../PayInvoice';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDataAction } from '../../../redux/Actions/ProfileActions';
import { amountCheckFn } from '../../../utils/commonUtil';

export default function MyInvoiceTableBody({ filters, payInvoice, billingDetails }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const getMyInvoicesFn = (filters = {}) => {
    setInvoiceLoading(true);
    const response = getMyInvoicesApi(filters);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setInvoices(res?.data);
        if (res?.data?.every((invoice) => invoice?.status == 'PAID')) {
          const temp = { ...profileData };
          temp.is_allowed_to_create_order = true;
          dispatch(getProfileDataAction(temp));
        }
        setInvoiceLoading(false);
      } else {
        setInvoiceLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const openPayInvoice = useCallback((e, val, id) => {
    e.stopPropagation();
    payInvoice(val, id);
  }, []);
  useEffect(() => {
    getMyInvoicesFn(filters);
  }, [filters]);
  return (
    <React.Fragment>
      {invoiceLoading ? (
        <tr>
          <td colSpan={5} className='py-10 text-center'>
            <Loader text='loading invoices...' classes='h-96' />
          </td>
        </tr>
      ) : invoices?.length === 0 ? (
        <tr>
          <td colSpan={5} className='py-10 text-center'>
            <NoData text='No invoices found' />
          </td>
        </tr>
      ) : (
        invoices?.map((invoice, idx) => {
          return (
            <tr
              className={`cursor-pointer ${idx % 2 ? '' : 'bg-slate-50'}`}
              onClick={() => navigate(`/invoice/${invoice?.transaction_id}`)}
              key={idx}
            >
              <td className='go-textblack-15 whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5'>
                {invoice?.transaction_id}
              </td>
              <td className='go-textblacknormal-15 whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5'>
                {dateFn('MM/DD/YYYY', invoice?.due_date)}
              </td>
              {invoice?.status === 'BAD_DEBT' ? (
                <td className='whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5'>
                  {invoice?.status && <StatusChip status='PAST_DUE' />}
                </td>
              ) : (
                <td className='whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5'>
                  {invoice?.status && <StatusChip status={invoice?.status} />}
                </td>
              )}

              <td className='go-text-semibold-15 whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5'>
                {amountCheckFn(invoice?.balance)}
              </td>
              <td className='go-text-semibold-15 whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5'>
                {amountCheckFn(invoice?.remaining_balance)}
              </td>
              <td className=' text-center'>
                {(invoice?.status === 'PAST_DUE' || invoice?.status === 'BAD_DEBT' || invoice?.status === 'SENT') && (
                  <div>
                    {billingDetails?.addresses[0]?.billing_details?.default_payment_method_type == 'CHECK' ? (
                      <span className=' go-textblacknormal-14'>
                        (<span className=' font-bold'>mail to: </span>PO Box 693 Centerburg, OH 43011){' '}
                      </span>
                    ) : billingDetails?.addresses[0]?.billing_details?.default_payment_method_type == 'ACH' ? (
                      <></>
                    ) : null}
                    <ClientButton
                      variant='primary'
                      text='Pay Now'
                      classes=''
                      size='xs'
                      onClick={(e) => openPayInvoice(e, true, invoice?.transaction_id)}
                    />
                  </div>
                )}
              </td>
            </tr>
          );
        })
      )}
    </React.Fragment>
  );
}
