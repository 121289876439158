import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderPreferencesApi } from '../../../redux/Reducers/MyAccountReducer';
import Cards from './Cards';
import SelectProviders from './SelectProviders';
import OrderPreferences from './OrderPreferences';
import OrderPreferencesModal from './OrderPreferencesModal';
import DropdownSingleAddress from '../../../components/DropdownSingleAddress';
import { isEmpty } from 'lodash';

export default function Settings({ allLocations }) {
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [providersModal, setProvidersModal] = useState(false);
  const [orderPreference, setOrderPreference] = useState(false);
  useEffect(() => {
    if (selectedAddress?.id) dispatch(getOrderPreferencesApi(selectedAddress?.id));
  }, [dispatch, selectedAddress]);
  return (
    <div className='sm:max-w-9xl w-full px-4 sm:mx-auto sm:px-6'>
      {/* {(profileData?.account_type == 'ORGANIZATION' || profileData?.account_type == 'ORGANIZATION_MEMBER') && ( */}
      {!isEmpty(allServiceAddresses) && (
        <div className='my-6 mt-3 flex w-full'>
          <div className='flex flex-col align-middle sm:flex-row sm:items-center'>
            <span className='go-textblackbold-16 mr-2'>Viewing info for:</span>
            <DropdownSingleAddress dropdownValues={allLocations} />
            {/* <button className='go-btn-primary ml-2 mt-1'>
                  <EditIcon onClick={() => setActiveTab('ServiceAddress')} />
                </button> */}
          </div>
        </div>
      )}

      {/* )} */}
      <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
        <Cards
          providersModal={providersModal}
          setProvidersModal={setProvidersModal}
          setOrderPreference={setOrderPreference}
        />
      </div>
      {/* {orderPreference && <OrderPreferences setOrderPreference={setOrderPreference} />} */}
      <SelectProviders providersModal={providersModal} setProvidersModal={setProvidersModal} />
      <OrderPreferencesModal modalOpen={orderPreference} setModalOpen={setOrderPreference} />
    </div>
  );
}
