import React from 'react';
import ClientButton from '../../../components/ClientButton';
import subscription from '../../../images/subscription.png';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import StatusChip from '../../../components/StatusChip';
import { addDaysAndGetWeekday, dateFn, getTimeZoneAbbr, utcTimeToLocalTime } from '../../../utils/dateTimeFormatter';
import no_picture from '../../../images/no-profile-picture.png';
import { isEmpty, isNumber } from 'lodash';
import { amountCheckFn, textCheckFn } from '../../../utils/commonUtil';
import moment from 'moment';
import {
  capitalizeFirstLetter,
  generateInitials,
  ordinal_suffix_of,
  unitTypeWithQuantity,
} from '../../../components/Utils';
import ToolTip from '../../../components/ToolTip';
import InfoCircle from '../../../components/Icons/InfoCircle';
export default function SubscriptionsCollapse({ list, deactivateModalFn }) {
  const informationCircleSvg = `
<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="#001442" fill-rule="nonzero">
        <path d="M8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333zm0 12A5.333 5.333 0 1 1 8 2.667a5.333 5.333 0 0 1 0 10.666z"/>
        <circle cx="8" cy="5.333" r="1"/>
        <path d="M8 6.667a.667.667 0 0 0-.667.666v3.334a.667.667 0 0 0 1.334 0V7.333A.667.667 0 0 0 8 6.667z"/>
    </g>
</svg>
`;
  const onModalOpen = (e, id) => {
    e.stopPropagation();
    deactivateModalFn(true, id);
  };
  const textChangeFn = (unit, obj, type, timezone) => {
    if (unit === 'WEEK') {
      if (type === 'pickup') {
        return `${capitalizeFirstLetter(obj?.pickup_week_day)}, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time,
          timezone
        )}`;
      } else {
        return `${capitalizeFirstLetter(obj?.dropoff_week_day)}, ${genarateTime(
          moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
          obj?.dropoff_start_time,
          obj?.dropoff_end_time,
          timezone
        )}`;
      }
    }
    if (unit === 'BIWEEKLY') {
      if (type === 'pickup') {
        return `Every other ${capitalizeFirstLetter(obj?.pickup_week_day)}, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time,
          timezone
        )}`;
      } else {
        return `Every other ${capitalizeFirstLetter(obj?.dropoff_week_day)}, ${genarateTime(
          moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
          obj?.dropoff_start_time,
          obj?.dropoff_end_time,
          timezone
        )}`;
      }
    }
    if (unit === 'MONTH') {
      if (type === 'pickup') {
        return `${ordinal_suffix_of(moment(obj?.first_pickup_date).date())} of each month, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time,
          timezone
        )}`;
      } else {
        return obj?.days_after === 1
          ? `1 day later, ${genarateTime(
              moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
              obj?.dropoff_start_time,
              obj?.dropoff_end_time,
              timezone
            )}`
          : `${obj?.days_after} days later, ${genarateTime(
              moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
              obj?.dropoff_start_time,
              obj?.dropoff_end_time,
              timezone
            )}`;
      }
    }
  };

  const subscriptionType = (type) => {
    if (type === 'WEEK') {
      return 'Weekly';
    } else if (type === 'MONTH') {
      return 'Monthly';
    } else if (type === 'BIWEEKLY') {
      return 'Bi-Weekly';
    } else {
      return '-';
    }
  };

  const genarateTime = (date, time1, time2, timezone) => {
    date = date ? date : moment().format('YYYY-MM-DD');
    return `${utcTimeToLocalTime(`${date.split('T')[0]}T${time1}`, timezone)} - ${utcTimeToLocalTime(
      `${date.split('T')[0]}T${time2}`,
      timezone
    )} (${getTimeZoneAbbr(`${date.split('T')[0]}T${time1}`, timezone)})`;
  };
  return (
    <div className='before:box-inherit after:box-inherit mx-auto mb-12 box-border columns-1 gap-4  xl:columns-2'>
      {list
        ?.sort((a, b) => {
          if (a?.status === 'CANCELLED') {
            return 1;
          }
          if (b?.status === 'CANCELLED') {
            return -1;
          }
          return 0;
        })
        .map((item, index) => {
          return (
            <div className='mb-4 break-inside-avoid rounded-lg bg-white p-7' key={index}>
              <div className='flex justify-between border-b-2 border-gray-100 pb-2'>
                <div className='mb-2 flex flex-col space-y-2'>
                  <div className=''>
                    <div className='flex flex-col sm:flex-row'>
                      <span className='go-textblackmedium-18 mr-4 font-semibold'>Subscription</span>
                      <div className='hidden sm:block'>
                        <StatusChip status={item?.status} size='sm' />
                      </div>
                    </div>
                    <span>#{item?.id}</span>
                  </div>
                  <div className=' sm:hidden'>
                    <StatusChip status={item?.status} size='sm' />
                  </div>
                  <div className='hidden sm:block'>
                    <div className='go-label-15 mt-2'>Start Date</div>
                    <div className='go-textblacknormal-15 font-medium'>
                      {item?.subscription_entries[0]?.first_pickup_date
                        ? `${dateFn('type2', item?.subscription_entries[0]?.first_pickup_date)}`
                        : '-'}
                    </div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='flex flex-col space-x-4 sm:flex-row'>
                    <div className='text-right'>
                      <div className='go-label-15'>Assigned Provider</div>
                      {item?.worker == null ? (
                        <div className='go-textnormal-15'>Auto-Match</div>
                      ) : (
                        <div className='go-textnormal-15'>
                          {item?.worker?.first_name +
                            (item?.worker?.last_name ? ` ${item?.worker?.last_name?.charAt(0)}` : '')}
                        </div>
                      )}
                    </div>

                    <div className='go-textblacknormal-15 mt-2 flex justify-end font-medium sm:mt-0'>
                      {item?.worker == null ? (
                        <img
                          className={'  h-[5rem] w-[5rem] rounded-full bg-[#f3f8ff]'}
                          src={no_picture}
                          alt={'Any Provider'}
                        />
                      ) : item?.worker?.profile_image ? (
                        <img
                          className={' h-[5rem] w-[5rem] rounded-full '}
                          src={item?.worker?.profile_image ? item?.worker?.profile_image?.absolute_url : no_picture}
                          alt={item?.worker?.first_name}
                        />
                      ) : (
                        <div className=' h-[5rem] w-[5rem] rounded-full bg-[#FD56AD] p-2'>
                          <span className='flex h-full w-full items-center justify-center text-3xl font-bold text-white'>
                            {generateInitials(item?.worker?.first_name + ' ' + item?.worker?.last_name)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col pt-2 sm:flex-row sm:pt-4'>
                <div className='sm:w-1/2'>
                  {item?.subscription_entries?.length > 1 ? (
                    <div>
                      <div className='rounded-sm border border-slate-200 '>
                        <header className='border-b border-slate-100 px-5 py-4'>
                          <h6 className='font-semibold text-slate-800'>Schedules</h6>
                        </header>
                        <div className='p-3'>
                          <div className='overflow-x-auto'>
                            <table className='w-full table-auto'>
                              <thead className='rounded-sm bg-slate-50 text-xs uppercase text-slate-400'>
                                <tr>
                                  <th className='p-2'>
                                    <div className='text-left font-semibold'>Frequency</div>
                                  </th>
                                  <th className='p-2'>
                                    <div className='text-center font-semibold'>First Pickup</div>
                                  </th>
                                  <th className='p-2'>
                                    <div className='text-center font-semibold'>Pickup</div>
                                  </th>
                                  <th className='p-2'>
                                    <div className='text-center font-semibold'>Delivery</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='divide-y divide-slate-100 text-sm font-medium'>
                                {item?.subscription_entries?.map((entry, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className='p-2'>
                                        {subscriptionType(item?.subscription_repeat_frequency_unit)}
                                      </td>
                                      <td className='p-2'>
                                        {item?.first_pickup_date ? `${dateFn('type2', entry?.first_pickup_date)}` : '-'}
                                      </td>
                                      <td className='p-2'>
                                        <div className=' text-center'>
                                          {textChangeFn(
                                            item?.subscription_repeat_frequency_unit,
                                            entry,
                                            'pickup',
                                            item?.customer_address?.time_zone
                                          )}
                                        </div>
                                      </td>
                                      <td className='p-2'>
                                        <div className=' text-center'>
                                          {textChangeFn(
                                            item?.subscription_repeat_frequency_unit,
                                            entry,
                                            'dropoff',
                                            item?.customer_address?.time_zone
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=''>
                      {/* <div className='mb-4 flex-row'>
                      <div className='go-label-15'>Start Date</div>
                      <div className='go-textblacknormal-15 font-medium'>
                        {item?.subscription_entries[0]?.first_pickup_date
                          ? `${dateFn('type2', item?.subscription_entries[0]?.first_pickup_date)}`
                          : '-'}
                      </div>
                    </div> */}
                      <div className='flex justify-between'>
                        <div className='mb-4 flex-row sm:hidden'>
                          <div className='go-label-15 mt-2'>Start Date</div>
                          <div className='go-textblacknormal-15 font-medium'>
                            {item?.subscription_entries[0]?.first_pickup_date
                              ? `${dateFn('type2', item?.subscription_entries[0]?.first_pickup_date)}`
                              : '-'}
                          </div>
                        </div>
                        <div className='mb-4 flex-row text-right sm:text-left'>
                          <div className='go-label-15 mt-2 sm:mt-0'>Frequency</div>
                          <div className='go-textblacknormal-15 font-medium'>
                            {subscriptionType(item?.subscription_repeat_frequency_unit)}
                          </div>
                        </div>
                      </div>
                      <div className='mb-4 flex-row'>
                        <div className='go-label-15'>Pickup</div>
                        <div className='go-textblacknormal-15 font-medium'>
                          {textChangeFn(
                            item?.subscription_repeat_frequency_unit,
                            item?.subscription_entries[0],
                            'pickup',
                            item?.customer_address?.time_zone
                          )}
                        </div>
                      </div>
                      <div className='mb-4 flex-row'>
                        <div className='go-label-15'>Delivery</div>
                        <div className='go-textblacknormal-15 font-medium'>
                          {textChangeFn(
                            item?.subscription_repeat_frequency_unit,
                            item?.subscription_entries[0],
                            'dropoff',
                            item?.customer_address?.time_zone
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='sm:w-1/2'>
                  <div className='mb-4 flex-row rounded-sm bg-slate-100 p-4'>
                    <div className='go-label-15'>Services</div>
                    <ul className='pl-4'>
                      {!isEmpty(item?.items) ? (
                        item?.items?.map((service, index) => {
                          return (
                            <li key={index} className='go-textblackmedium-15 my-1 list-disc'>
                              {service?.service?.name}
                              <div className='go-textblackmedium-13'>
                                {unitTypeWithQuantity(service?.service?.unit_type, service?.quantity)}
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div className='go-textblackmedium-15 my-1'>No items</div>
                      )}
                    </ul>
                  </div>

                  <Disclosure key={index} defaultOpen={false}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className='flex w-full justify-between'>
                          <div className='flex'>
                            <span className='go-label-15'>Estimated Total</span>
                            <ToolTip
                              classes='w-64 bg-white shadow-lg'
                              icon={<InfoCircle classes={'w-5 h-5 ml-2 mt-0.5'} />}
                            >
                              <ul className='go-label-13 list-disc border px-6  py-3 text-left'>
                                <li>Sales tax calculated upon delivery.</li>
                                <li>
                                  We have a ${item?.order_minimum_charge} minimum charge policy. If your total is less
                                  than ${item?.order_minimum_charge} we will increase the total to this amount.
                                </li>
                              </ul>
                            </ToolTip>
                          </div>
                          <div className='flex'>
                            <div className='go-textblacknormal-15 font-medium'>{amountCheckFn(item?.final_total)}</div>
                            <ChevronDownIcon
                              className={`${!open ? 'rotate-180 transform' : ''} go-heading-16 m-0.5 mx-1 ml-2 h-5 w-3`}
                            />
                          </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className='rounded-sm border-b border-gray-100 bg-slate-100'>
                          <div className='flex-row'>
                            <section className='p-4'>
                              <ul>
                                <li className=' flex items-center justify-between pb-1'>
                                  <div className='go-label-16'>Cost Breakdown</div>
                                </li>
                                <li className=' flex items-center justify-between pb-1'>
                                  <div className='go-label-15'>Subtotal:</div>
                                  <div className='go-text-15'>
                                    <span>{amountCheckFn(item?.subtotal)}</span>
                                  </div>
                                </li>
                                {/* <li className=' flex items-center justify-between py-1'>
                                <div className='go-label-15'>Estimated Sales Tax:</div>
                                <div className='go-text-15'>
                                  <span>{amountCheckFn(item?.estimated_tax_cost)}</span>
                                </div>
                              </li> */}
                                <li className=' flex items-center justify-between py-1'>
                                  <div className='go-label-15'>Discount:</div>
                                  <div className='go-text-15'>
                                    <span>-{amountCheckFn(item?.estimated_discount_amount)}</span>
                                  </div>
                                </li>

                                <li className=' flex items-center justify-between py-1'>
                                  <div className='go-label-15'>Service Fee:</div>
                                  <div className='go-text-15'>
                                    <span>{amountCheckFn(item?.service_fee)}</span>
                                  </div>
                                </li>
                                {isNumber(item?.total_priority_increase) && item?.total_priority_increase > 0 && (
                                  <li className=' flex items-center justify-between py-1'>
                                    <div className='go-text-15 text-client-link'>Priority:</div>
                                    <div className='go-text-15 text-client-link'>
                                      <span>{amountCheckFn(item?.total_priority_increase)}</span>
                                    </div>
                                  </li>
                                )}

                                {/* <li className=' flex items-center justify-between py-1'>
                                <div className='go-label-15'>Estimated Total Cost:</div>
                                <div className='go-text-15'>
                                  <span>{amountCheckFn(item?.final_total)}</span>
                                </div>
                              </li> */}
                                {/* {item?.order_minimum_charge && item?.order_minimum_charge > 0 && (
                                <li className=' flex py-1'>
                                  <p className='go-text-14 text-client-link'>
                                    We have a ${item?.order_minimum_charge} minimum charge policy. If your subtotal is
                                    less than ${item?.order_minimum_charge} we will increase the subtotal to this
                                    amount.
                                  </p>
                                </li>
                              )} */}
                              </ul>
                            </section>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
              <div className='mt-2 flex justify-start'>
                <span className='go-textnormal-15 go-link-16 cursor-pointer' onClick={(e) => onModalOpen(e, item?.id)}>
                  Deactivate
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}
